<template>
  <div v-if="globalWeekData" class="overview-class-container">
    <div class="title-background">
      <div class="left-title-section">
        <h1 class="page-section-title"> {{ $t('OVERVIEW.WELCOME_CLASS') + ' ' + profile.name }}! &#x1F389; </h1>
        <p class="title-description">{{ $t('OVERVIEW.PROFESSIONAL_EXCLUSION') }}</p>
      </div>

      <div class="right-title-section">
        <div class="right-title-section-top">
          <div v-if="weekActive" class="green-dot"></div>
          <h2 class="page-section-title"> {{ $t(weekTitle) }}</h2>
        </div>
        <div v-if="weekActive || weekSubtitle" class="right-title-section-bottom">
          <a v-if="weekActive" :href="missionUrl" target="_blank">Ga naar missie overzicht</a>
          <p v-else-if="weekSubtitle" class="subtitle">{{ $t(weekSubtitle) }}</p>
        </div>
      </div>
    </div>
    <OverviewMission
      v-if="globalWeekData && store.getters.getGameOverview"
      :week-status="globalWeekData.status"
      :start-time="Date.parse(globalWeekData.startDate) / 1000"
      :mission-data="store.getters.getGameOverview.nextUnlockedMission"
      :class-id="-1"
      :professional="true"
    />
    <PreWeekDemo v-if="globalWeekData.status === UPCOMING_WEEK_STATUS" />
    <DiscoverMMExtra v-else />
  </div>
</template>

<script>

import PreWeekDemo from '@/components/partials/overview/PreWeekDemo'
import OverviewMission from '@/components/elements/missions/OverviewMission'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import { PASSED_WEEK_STATUS, ACTIVE_WEEK_STATUS, UPCOMING_WEEK_STATUS } from '../../../utils/data/weekStatus'
import { GET_WEEK_DATA } from '../../../store/actions'
import DiscoverMMExtra from '@/components/partials/overview/DiscoverMMExtra'
import { GET_GAME_OVERVIEW_PROF } from '@/store/modules/teacherPortal/actions'

export default {
  name: 'OverviewProfessional',
  components: {
    DiscoverMMExtra,
    PreWeekDemo,
    OverviewMission,
  },
  setup() {
    const store = useStore()
    const profile = computed(() => store.getters.getTeachersProfile)

    const globalWeekData = computed(() => store.getters.getWeekData)
    store.dispatch(GET_WEEK_DATA).then(() => {
      if (globalWeekData.value.status === 'active') {
        store.dispatch(GET_GAME_OVERVIEW_PROF)
      }
    })
    const currentDay = computed(() => globalWeekData.value ? globalWeekData.value.currentDay : null)

    const isPostWeek = computed(() => globalWeekData.value && globalWeekData.value.status === PASSED_WEEK_STATUS)
    const weekActive = computed(() => globalWeekData.value && globalWeekData.value.status === ACTIVE_WEEK_STATUS)
    const preWeek = computed(() => globalWeekData.value && globalWeekData.value.status === UPCOMING_WEEK_STATUS)

    const weekTitle = computed(() => {
      if (preWeek.value) {
        return 'OVERVIEW.MISSION.PRE_WEEK.TITLE'
      } else if (weekActive.value && currentDay.value === 1) {
        return 'OVERVIEW.MISSION.DURING_WEEK_FIRST_MISSION.TITLE'
      } else if (weekActive.value && currentDay.value > 1) {
        return 'OVERVIEW.MISSION.DURING_WEEK_OTHER_MISSIONS.TITLE'
      } else if (isPostWeek.value) {
        return 'OVERVIEW.MISSION.POST_WEEK_RESULTS.TITLE'
      } else {
        return ''
      }
    })

    const weekSubtitle = computed(() => {
      if (weekActive.value) {
        return ''
      } else if (isPostWeek.value) {
        return 'OVERVIEW.MISSION.WEEK_ENDED'
      } else {
        return 'OVERVIEW.MISSION.WEEK_ALMOST_STARTS'
      }
    })

    const missionUrl = `${process.env.VUE_APP_ROOT_GAME}/selecteer-missie/-1`

    const mediamastersExtraRoute = `${process.env.VUE_APP_ROOT_GAME}/extra-games`


    return {
      missionUrl,
      mediamastersExtraRoute,
      globalWeekData,
      profile,
      store,

      weekActive,
      weekTitle,
      weekSubtitle,

      UPCOMING_WEEK_STATUS,
      ACTIVE_WEEK_STATUS,
      PASSED_WEEK_STATUS
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";
$right-bar-width: rem(0);

.overview-class-container {
  min-width: rem(600);
  margin-right: $right-bar-width;
  max-width: 1400px;
  padding: rem(32) rem(49);

  .current-week {
    margin-bottom: rem(60);
  }

  .extra-bits-section {
    max-width: rem(730);
    margin: 0 auto;
    h2 {
      text-align: center;
      margin-bottom: rem(20);
    }
    .extra-missions {
      margin-top: rem(20);
      max-width: rem(730);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: rem(20);
    }
  }
}

.title-background {
  margin-bottom: rem(25);
  margin-top: rem(11);
  background-color: rgb(255, 255, 255);
  border-radius: rem(20);
  padding: rem(30);
  display: flex;
  justify-content: space-between;
  gap: rem(16);

  .title-description {
    opacity: .7;
  }

  .subtitle {
    margin-top: rem(8);
    font-size: rem(14);
    color: var(--purple_light)
  }
}

.right-title-section {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .right-title-section-top {
    display: flex;
    align-items: center;
    width: rem(450);
    justify-content: flex-end;

    .green-dot {
      height: 12px;
      width: 12px;
      background-color: #85ff87;
      border-radius: 50%;
      margin-right: 12px;
      flex-shrink: 0;
    }
  }

  .right-title-section-bottom {
    margin-top: 10px;
  }
}

.section-text {
  margin-bottom: rem(42);
}

.right-sidebar-day {
  border-radius: 20px;
  background: white linear-gradient(180deg, #3240A2 0%, #1C287D 100%);
  text-align: center;
  height: rem(500);
  position: relative;
  margin-bottom: rem(50);
  margin-top: rem(20);

  .day-text {
    background-color: #212D84;
    border-radius: 17.5px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(24);
    padding: rem(8);
  }

  .middle-text {
    // top: 70%;
    position: relative;
    top: rem(300);
  }

  .bottom-button{
    position: relative;
    top: rem(60)
  }
}

.day-streak{
  display: flex;
  gap: rem(12);
  margin-top: rem(15);
}

.completed-day{
  display: inline;
  padding: rem(6) rem(6);
  background-color: gold;
  border-radius: rem(12);
}

.day{
  padding: rem(35) rem(6);
  background-color: gold;
  border-radius: rem(12);
}

.completed{
  background-color: gold;
}

.uncompleted{
  background-color: purple;
}

.uncompleted-day{
  display: inline;
  padding: rem(20) rem(20) rem(50);
  background-color: purple;
  margin: rem(6);
  border-radius: rem(12);
}

.streak-bar{
  position: relative;
  top: rem(100);
  padding: rem(2) rem(20) rem(2) rem(20);
  border-radius: rem(50);
}

.completed{
  background-color: gold;

}

.uncompleted{
  background-color: purple;
}

:deep .misison-overview {
  margin-bottom: rem(25);
}
</style>
