<template>
  <div class="wrapper">
    <div class="discover-mm-extra">
      <div class="container">
        <h1>{{ $t('DISCOVER_MM_EXTRA.TITLE') }}</h1>
        <p>{{ $t('DISCOVER_MM_EXTRA.DESCRIPTION') }}</p>
        <a :href="extraGamesRoute" target="_blank">
          <button class="pink-button">{{ $t('DISCOVER_MM_EXTRA.BUTTON') }}</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscoverMMExtra',
  computed: {
    extraGamesRoute() {
      return process.env.VUE_APP_ROOT_GAME + '/extra-games?no-class=true'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.wrapper {
  width: 100%;
  height: rem(448);
}

.discover-mm-extra {
  p, h1 {
    color: white
  }

  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/img_mediawisdom@3x.png") no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: rem(10);

  .container {
    position: absolute;
    top: rem(76);
    left: rem(78);
    bottom: rem(76);

    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      text-transform: uppercase;
      font-size: 34px;
    }

    p {
      max-width: 50%;
    }

    button {
      width: fit-content;
    }

  }
}

</style>
